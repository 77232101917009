import React, { useEffect, useState } from 'react'
import { GetComments } from '@src/services/api-client'
import { UserName } from '@src/modules/Users/shared/UserName'
import { Button, Loading } from '@therms/atalaya'
import { CommentImageLightbox } from './CommentImageLightbox'
import { Comment } from '@src/generatedClient/generated/taskreportsApi'

interface CommentsProps {
  commentIds?: string[]
  siteId: string
}

function Comments({ commentIds, siteId }: CommentsProps) {
  const commentIdsResolved = commentIds || []
  const [comments, setComments] = useState<Comment[]>([])
  const [showImages, setShowImages] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (commentIdsResolved.length && siteId) {
      setLoading(true)
      GetComments({ commentIds: commentIdsResolved, siteId })
        .then((data) => setComments(data.data))
        .finally(() => setLoading(false))
    }
  }, [commentIdsResolved])

  return (
    <div className="relative">
      {loading && <Loading overlay />}

      {comments.map((comment) => (
        <div>
          <div className="mb-base whitespace-pre-wrap">{comment.userNote}</div>
          <em className="text-sm text-neutral">
            <UserName id={comment.userId} />
          </em>
          {comment.fileUrls.length > 0 && (
            <div className="mt-1">
              <Button
                onClick={() => {
                  setShowImages(true)
                }}
                size="xs"
              >
                Click for Images
              </Button>
            </div>
          )}
        </div>
      ))}

      {!commentIdsResolved.length && (
        <span className="text-neutral">No comments</span>
      )}
      {showImages && (
        <CommentImageLightbox
          onClosePress={() => {
            setShowImages(false)
          }}
          siteId={siteId}
          commentIds={commentIdsResolved}
        />
      )}
    </div>
  )
}

Comments.defaultProps = {}

export { Comments }
